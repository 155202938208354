export default {
  // 温柔的点击按钮 (防止连点)
  mounted(el: any, binding: any) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style['pointer-events'] = 'none'
        setTimeout(() => {
          el.disabled = false
          el.style['pointer-events'] = 'unset'
        }, binding.value || 300)
      }
    })
  }
}
