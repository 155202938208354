import { App as AppType, createApp } from 'vue'
import App from '@/App.vue'
import '@/styles/index.less'
import { setupStore } from '@/store'
import { setupRouter } from './router'
import { setupDirective } from '@/directive'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import setupI18n from './locales/setupI18n'
import { TextPlugin } from 'gsap/all'
import { create, NCarousel } from 'naive-ui'
import 'amfe-flexible' //导入

import 'virtual:svg-icons-register'

const setupNaive = (app: AppType) => {
  const naive = create({ components: [NCarousel] })
  app.use(naive)
}

async function bootstrap() {
  const app = createApp(App)
  await setupI18n(app)
  setupDirective(app)
  setupNaive(app)
  setupStore(app)
  setupRouter(app)
  gsap.registerPlugin(TextPlugin)
  gsap.registerPlugin(ScrollTrigger)

  app.mount('#app')
}

bootstrap()
