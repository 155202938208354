export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        name: 'Home',
        path: '/',
        component: () => import('@/views/Home/Home.vue'),
        meta: {
          title: 'Data4o'
        }
      }
    ]
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('@/layouts/ShareLayout.vue'),
    children: [
      //---------------------------share---------------------------
      {
        name: 'ShareDataset',
        path: '/share/dataset/:_id/:code',
        component: () => import('@/views/Share/DataSet.vue'),
        meta: {
          title: '数据集',
          ico: '/favicon_dataset.ico',
          originalPath: '/dataset/:_id/:code'
        }
      },
      {
        name: 'ShareDatasetList',
        path: '/share/dataset/list/:_id',
        component: () => import('@/views/Share/DataSetList.vue'),
        meta: {
          title: '数据集',
          ico: '/favicon_dataset.ico',
          originalPath: '/datasetShareList/:_id'
        }
      }
    ]
  }
]
