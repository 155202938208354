import { nextTick } from 'vue'

export default {
  mounted(el: any, binding: any) {
    skeletonCotrol(el, binding)
  },
  updated(el: any, binding: any) {
    skeletonCotrol(el, binding)
  }
}

const skeletonCotrol = (el: any, binding: any) => {
  nextTick(() => {
    const className = el.className.split(' ')
    const loading =
      Object.prototype.toString.call(binding.value) === '[object Object]'
        ? binding.value.value
        : binding.value

    if (loading) {
      const createSkeletonDom = document.createElement('div')
      createSkeletonDom.className = 'm-skeleton'
      if (className.find((item) => item == 'n-data-table')) {
        const hasSkeleton = el.querySelector('.m-skeleton__n-data-table')
        if (!hasSkeleton) {
          // 将空数据svg隐藏
          const empty = el.querySelector('.empty-wrapper')
          if (empty) {
            empty.style.opacity = 0
          }

          // 获取表格width
          const thead = el.querySelector('.n-data-table-thead')
          const colgroup = el.querySelector('colgroup')
          const ths = colgroup.querySelectorAll('col')

          const widths: string[] = []

          for (let i = 0, len = ths.length; i < len; i++) {
            const width = ths[i].style.width
            if (width) widths.push(width)
          }

          // 填充skeleton
          const wrap = el.querySelector('.n-data-table-base-table')
          const createSkeletonDomWrap = document.createElement('div')
          createSkeletonDomWrap.className = 'm-skeleton__n-data-table'
          if (binding.value?.thHeight || thead.clientHeight)
            createSkeletonDomWrap.style.paddingTop =
              binding.value.thHeight || thead.clientHeight + 'px'

          const createSkeletonDomLiWrap = document.createElement('div')
          createSkeletonDomLiWrap.className = 'm-skeleton-li-wrap'

          for (let i = 0, len = widths.length; i < len; i++) {
            const createSkeletonDomChild = document.createElement('div')
            createSkeletonDomChild.className = 'm-skeleton-wrap'
            createSkeletonDomChild.append(createSkeletonDom.cloneNode(true))

            createSkeletonDomChild.style.width = widths[i]
            if (widths[i]) createSkeletonDomChild.style.flex = 'none'
            createSkeletonDomLiWrap.append(createSkeletonDomChild)
          }

          for (let i = 0; i < 3; i++) {
            createSkeletonDomWrap.append(createSkeletonDomLiWrap.cloneNode(true))
          }

          wrap.append(createSkeletonDomWrap)
        }
      } else {
        el.append(createSkeletonDom)
      }
    } else if (!loading) {
      if (className.find((item) => item == 'n-data-table')) {
        // 将空数据svg显示
        const empty = el.querySelector('.empty-wrapper')
        if (empty) {
          empty.style.opacity = 1
        }

        const wraps = el.querySelectorAll('.m-skeleton__n-data-table')
        for (let i = 0; i < wraps.length; i++) {
          wraps[i] && wraps[i].parentNode.removeChild(wraps[i])
        }
      } else {
        const wraps = el.querySelectorAll('.m-skeleton')
        for (let i = 0; i < wraps.length; i++) {
          wraps[i] && wraps[i].parentNode.removeChild(wraps[i])
        }
      }
    }
  })
}
