import { App } from 'vue'
import baseRoutes from './routes/baseRoutes'
import { createRouter, createWebHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(),
  routes: [...baseRoutes],
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export function setupRouter(app: App): void {
  app.use(router)
}
